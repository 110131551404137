import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import Layout from '../components/layout'
import Avatar from '../images/avatar-placeholder.png'
import Org from '../images/org-placeholder.png'
import Donation from '../images/ic-donation.png'

import {ChevronRight} from '@styled-icons/octicons'
import {graphql, Link} from 'gatsby'
import PersonalVerdictItem from '../components/verdict-item'
import PositionItem from '../components/postion-item'
import SEO from '../components/seo'
import queryString from "query-string";
import axios from "axios";

const config = require('../config/default');

import ballot from "../images/ic-ballot.png";
import hammer from "../images/ic-hammer.png";
import chairman from "../images/ic-chairman.png";
import {format} from "date-fns";

function Person() {
    const search = typeof window !== `undefined` ? location.search : {q: '/none/'}
    let params = queryString.parse(search)

    let personId = params.id
    // console.log("personId", personId)


    const [state, setState] = useState({
        person: {
            "name": "",
            "current": "",
            "sortname": "",
            "image": "",
            "isInvolved": false,
            "involvedRelation": "",
            "partiesMemberids": [],
            "partiesDonorids": [],
            "partiesDefendantsPersonids": [],
            "assets": "-",
            "spouseAssets": "-",
            "childrenAssets": "-",
            "debt": "-",
            "spouseDebt": "-",
            "childrenDebt": "-",
        },
        donateToParties: [],
        donateTransactions: [],
        personId: personId,
        previousJobDocuments: [],
        relateOrgs: [],
        relatives: [],
        loading: false,
        is_search: true
    });


    let FIND_PEOPLE = function (search) {
        // console.log("FIND_PEOPLE", search)


        if (search === null) return []

        let query = `
            query ($uid: String) {
allPeople(id: $uid) {
    edges {
      node {
        name
        sortname
        image
        partiesMemberids {
          label
          role
          organizationid {
            id
            name
            image
            partytype
          }
        }
        partiesDonorids {
          donatetoid {
            name
            image
            partytype
          }
          donatedat
          value
        }
        partiesDefendantsPersonids {
          id
          name
          position
          judgement
          verdictnote
          verdictid {
            verdictdate
            redcaseid
            blackcaseid
            accusedfor
            note
          }
        }
      }
    }
  }
}
          `.replace(/\n/g, ' ');

        new Promise((resolve, reject) => {
            axios.post(config.GRAPHQL_API_URL, {
                query: query,
                variables: {
                    "uid": state.personId
                }
            })
                .then(function (response) {
                    if (response.data.data.allPeople.edges.length > 0) {
                        let person = response.data.data.allPeople.edges[0].node;
                        const parties = []
                        const transactions = []
                        person.partiesDonorids
                            .forEach(it => {
                                transactions.push(it)
                                let p = parties.find(p => p.id === it.id)
                                if (!p) {
                                    p = it
                                    p.totalReceiveAmount = 0.0
                                    parties.push(p)
                                }
                                p.totalReceiveAmount = (p.totalReceiveAmount || 0) + (it.value || 0)
                            })

                        let current = ""
                        if (person.partiesMemberids.length > 0) {
                            person.partiesMemberids.forEach(function (it) {
                                current = it.role
                            })
                            person["current"] = { role: current}
                        }

                        let isInvolved =  person["current"] === "mp"  ||   person["current"] === "leader" ||   person["current"] === "ex-mp"
                        if (person.partiesDonorids.length > 0) {
                            isInvolved = true
                        }

                        person["assets"] = "-"
                        person["spouseAssets"] = "-"
                        person["childrenAssets"] = "-"
                        person["debt"] = "-"
                        person["spouseDebt"] = "-"
                        person["childrenDebt"] = "-"
                        person["isInvolved"] = isInvolved

                        let relateOrgs = [];
                        let relateOrgsNames = [];

                        if (person.partiesMemberids.length > 0) {
                            person.partiesMemberids.forEach(function (item) {
                                item.isParty = true;
                                // console.log(item)
                                if (relateOrgsNames.indexOf(item.organizationid.name) == -1) {
                                    relateOrgs.push(item)
                                    relateOrgsNames.push(item.organizationid.name)
                                }
                            })
                        }

                        let newDataState = {
                            "is_search": false,
                            "loading": false,
                            "donateToParties": parties,
                            "donateTransactions": transactions,
                            "personId": state.personId,
                            "person": person,
                            "previousJobDocuments": [],
                            "relateOrgs": relateOrgs,
                            "relatives": [],
                        }
                        setState(newDataState);

                        FIND_PEOPLE_ADDITIONAL_INFO(person, person.sortname, newDataState)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

            setState({
                "is_search": false,
                "loading": true,
                "donateToParties": [],
                "donateTransactions": [],
                "personId": state.personId,
                "previousJobDocuments": [],
                "relateOrgs": [],
                "relatives": [],
                "person": {
                    "name": "",
                    "current": "",
                    "sortname": "",
                    "image": "",
                    "isInvolved": false,
                    "involvedRelation": "",
                    "previousJobDocuments": [],
                    "relatives": [],

                    "assets": "-",
                    "spouseAssets": "-",
                    "childrenAssets": "-",
                    "debt": "-",
                    "spouseDebt": "-",
                    "childrenDebt": "-",

                    "partiesMemberids": [],
                    "partiesDonorids": [],
                    "partiesDefendantsPersonids": []
                }
            });

            return resolve(true);
        }).then(r => console.log("success"));

    }

    let FIND_PEOPLE_ADDITIONAL_INFO = function (person, search, newDataState) {
        // console.log("FIND_PEOPLE_ADDITIONAL_INFO", search)

        let query = `
query($search: String) {
  allDocuments(search: $search) {
    edges {
      node {
        id
        
        firstNameTh
        middleNameTh
        lastNameTh
        
        assetsValue
        debtValue
        additionPositionDocuments {
          company {
            title
          }
          positionTitle
          personType
        }
        previousJobDocuments {
          company {
            title
          }
          endYear
          positionTitle
        }
        
        spouseFirstNameTh
        spouseMiddleNameTh
        spouseLastNameTh
        
        fatherFirstName
        fatherMiddleName
        fatherLastName
        
        motherFirstName
        motherMiddleName
        motherLastName
        
        spouseFatherFirstName
        spouseFatherMiddleName
        spouseFatherLastName
        
        spouseMotherFirstName
        spouseMotherMiddleName
        spouseMotherLastName
        
        additionalSpouseDocuments {
          firstNameTh
          middleNameTh
          lastNameTh
        }
        
        relationDocuments {
          firstName
          middleName
          lastName
        }
        
        childrenDocuments {
          firstName
          middleName
          lastName
        }
        
        assetsDocuments {
          valueBaht
          personType
          assetsType
        }
        
        debtDocuments {
          valueBaht
          personType
          debtType
        }
      }
    }
  }
}
          `.replace(/\n/g, ' ');


        new Promise((resolve, reject) => {
            axios.post(config.DECLARE_DOCUMENT_GRAPHQL_API_URL, {
                query: query,
                variables: {
                    "search": search
                }
            })
                .then(function (response) {

                    function numberWithCommas(x) {
                        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }

                    // console.log("response.data.data", (response.data.data.allDocuments.edges));
                    // console.log("response.data.data", (newDataState));

                    if (response.data.data.allDocuments.edges.length > 0) {
                        let document = response.data.data.allDocuments.edges[0].node

                        let assets = ""
                        let spouseAssets = ""
                        let childrenAssets = ""
                        let debt = ""
                        let spouseDebt = ""
                        let childrenDebt = ""

                        let relatives = [];

                        // "spouseFirstNameTh": "สุมณฑินี",
                        //     "spouseMiddleNameTh": null,
                        //     "spouseLastNameTh": "สมัครพงศ์",

                        if (document.spouseFirstNameTh != null) {

                            relatives.push({
                                name: document.spouseFirstNameTh + (document.spouseMiddleNameTh == null || document.spouseMiddleNameTh == "-" ? "" : (" " + document.spouseMiddleNameTh)) + (document.spouseLastNameTh == null || document.spouseLastNameTh == "-" ? "" : (" " + document.spouseLastNameTh)),
                                relativeType: "คู่สมรส"
                            })
                        }


                        let additionalSpouseDocuments = document.additionalSpouseDocuments;
                        additionalSpouseDocuments.forEach(function (d) {
                            relatives.push({
                                name: d.firstName + (d.middleName == null || d.middleName == "-" ? "" : (" " + d.middleName)) + (d.lastName == null || d.lastName == "-" ? "" : (" " + d.lastName)),
                                relativeType: "คู่สมรส"
                            })
                        })

                        let childrenDocuments = document.childrenDocuments;
                        childrenDocuments.forEach(function (d) {
                            relatives.push({
                                name: d.firstName + (d.middleName == null || d.middleName == "-" ? "" : (" " + d.middleName)) + (d.lastName == null || d.lastName == "-" ? "" : (" " + d.lastName)),
                                relativeType: "บุตร"
                            })
                        })

                        relatives.push({
                            name: document.fatherFirstName + (document.fatherMiddleName == null || document.fatherMiddleName == "-" ? "" : (" " + document.fatherMiddleName)) + (document.fatherLastName == null || document.fatherLastName == "-" ? "" : (" " + document.fatherLastName)),
                            relativeType: "บิดา"
                        })
                        relatives.push({
                            name: document.motherFirstName + (document.motherMiddleName == null || document.motherMiddleName == "-" ? "" : (" " + document.motherMiddleName)) + (document.motherLastName == null || document.motherLastName == "-" ? "" : (" " + document.motherLastName)),
                            relativeType: "มารดา"
                        })

                        let relationDocuments = document.relationDocuments;
                        relationDocuments.forEach(function (d) {
                            relatives.push({
                                name: d.firstName + (d.middleName == null || d.middleName == "-" ? "" : (" " + d.middleName)) + (d.lastName == null || d.lastName == "-" ? "" : (" " + d.lastName)),
                                relativeType: "พี่น้อง"
                            })
                        })


                        let assetsDocuments = document.assetsDocuments;
                        assetsDocuments.forEach(function (d) {
                            if (d.personType === "SELF" && d.assetsType === "ALL") {
                                assets = numberWithCommas(d.valueBaht)
                            }
                            if (d.personType === "SPOUSE" && d.assetsType === "ALL") {
                                spouseAssets = numberWithCommas(d.valueBaht)
                            }
                            if (d.personType === "NON_LEGAL_AGE_CHILD" && d.assetsType === "ALL") {
                                childrenAssets = numberWithCommas(d.valueBaht)
                            }
                        })

                        let debtDocuments = document.debtDocuments;
                        debtDocuments.forEach(function (d) {
                            if (d.personType === "SELF" && d.debtType === "ALL") {
                                debt = numberWithCommas(d.valueBaht)
                            }
                            if (d.personType === "SPOUSE" && d.debtType === "ALL") {
                                spouseDebt = numberWithCommas(d.valueBaht)
                            }
                            if (d.personType === "NON_LEGAL_AGE_CHILD" && d.debtType === "ALL") {
                                childrenDebt = numberWithCommas(d.valueBaht)
                            }
                        })

                        let previousJobs = []
                        let previousJobDocuments = document.previousJobDocuments;
                        previousJobDocuments.forEach(function (d) {
                            let prefixes = ['บริษัท ', 'บริษัท', 'จำกัด (มหาชน)', 'จำกัด', 'ห้างหุ้นส่วน ', 'ห้างหุ้นส่วน', 'บจก.']
                            let searchCompanyName = d.company.title;
                            prefixes.forEach(function (prefix) {
                                searchCompanyName = searchCompanyName.replace(prefix, "")
                            })

                            if (searchCompanyName[searchCompanyName.length-1] == " ") {
                                searchCompanyName = searchCompanyName.substr(0, searchCompanyName.length-1)
                            }
                            previousJobs.push({
                                companyName: d.company.title,
                                searchCompanyName: searchCompanyName,
                                positionTitle: d.positionTitle,
                                endYear: (d.endYear == null || d.endYear == "-" ? "-" : (d.endYear)),

                            })
                        })
                        previousJobs.sort(function (a, b) {
                            if (a.endYear < b.endYear) return 1;
                            if (a.endYear > b.endYear) return -1;
                            return 0;
                        });

                        person["assets"] = assets
                        person["spouseAssets"] = spouseAssets
                        person["childrenAssets"] = childrenAssets
                        person["debt"] = debt
                        person["spouseDebt"] = spouseDebt
                        person["childrenDebt"] = childrenDebt

                        // console.log(newDataState);
                        // console.log(newDataState.donateTransactions)

                        let relateOrgs = [];
                        let relateOrgsNames = [];

                        if (person.partiesMemberids.length > 0) {
                            person.partiesMemberids.forEach(function (item) {
                                item.isParty = true;
                                if (relateOrgsNames.indexOf(item.organizationid.name) == -1) {
                                    relateOrgs.push(item)
                                    relateOrgsNames.push(item.organizationid.name)
                                }
                            })
                        }
                        if (previousJobs.length > 0) {
                            previousJobs.forEach(function (item) {
                                item.isParty = false;
                                if (relateOrgsNames.indexOf(item.companyName.replace(/พรรค/g, '')) == -1) {
                                    relateOrgs.push(item)
                                    relateOrgsNames.push(item.companyName.replace(/พรรค/g, ''))
                                }
                            })
                        }

                        setState({
                            "is_search": false,
                            "loading": false,
                            "donateToParties": newDataState.donateToParties,
                            "donateTransactions": newDataState.donateTransactions,
                            "personId": newDataState.personId,
                            "person": person,
                            "previousJobDocuments": previousJobs,
                            "relateOrgs": relateOrgs,
                            "relatives": relatives,
                        });

                    }


                })
                .catch(function (error) {
                    console.log("error", error);
                });

            return resolve(true);
        }).then(r => console.log("success"));


        setState(newDataState);


    }


    if (state.is_search) FIND_PEOPLE(personId);
    const person = state.person
    const donateToParties = state.donateToParties
    const donateTransactions = state.donateTransactions.sort(function (a, b) {
        return new Date(a.donatedat) >= new Date(b.donatedat)? 1: -1
    })
    const relatives = state.relatives
    const previousJobDocuments = state.previousJobDocuments

    const  relateOrgs = state.relateOrgs

    const [isShowTransaction, setIsShowTransaction] = useState(false)


    let convertDateToThai = function (date) {
        var monthsTh =  [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม", ];

        return format(date, 'dd') + " " +
            monthsTh[parseInt(format(date, 'MM')) - 1] + " " +
            (parseInt(format(date, 'yyyy')) + 543)
    }

    let setExpandTransaction = function () {
        setIsShowTransaction(!isShowTransaction);
    }

    const headerBlock = () => (<div className="">
        <div className="relative text-center">
            <img src={Avatar} alt="avatar image"
                 className=""/>
        </div>
        <h1 className="text-white text-center sm:text-left text-xl sm:text-2xl md:text-4xl">{person.name}</h1>
    </div>)


    const getRoleName = (position) => {
        if (typeof(position) === 'undefined') return "";

        let role = position['role'];
        if (typeof(role) === 'undefined') return "";
        return {
            leader: 'หัวหน้าพรรค',
            mp: 'ส.ส.',
            "ex-mp": '-',
            senator: 'วุฒิสภา (ส.ว.)',
            legislator: 'สภานิติบัญญัติแห่งชาติ'
        }[role]
    }

    const getPartyName = (party) => {
        if (party === 'Parliament') {
            return "วุฒิสภา"
        }
        return party;
    }


    return (
        <Layout>
            <SEO
                keywords={[`politician`, `นักการเมือง`]}
                title={person.name}
            />

            <div className="grid grid-cols-1 md:grid-cols-4 min-h-full" style={{'min-height': '80vh'}}>
                <div className="bg-sidebar">
                    <div className="">
                        <div className="flex justify-center -mt-5">
                            { person.image? <img src={person.image} alt="avatar image"
                                                 className="rounded-full shadow-xl w-16 md:w-20 h-16 md:h-20 object-cover"/>:
                                <img src={Avatar} alt="avatar image"
                                     className="rounded-full shadow-xl w-16 md:w-20"/>
                            }
                        </div>
                        <div className="mt-2">
                            <h1 className="px-5 text-black text-center text-xl sm:text-xl md:text-2xl">{person.name}</h1>
                            { person.current == "ex-mp"? <h4 className="px-5 text-black text-center">{getRoleName(person.current)}</h4>: <p></p> }

                        </div>

                        <div className="mt-10">
                            <h4 className="px-5 font-bold text-black text-left ">ข้อมูลทรัพย์สิน หนี้สิน จาก ปปช.</h4>
                            <div className="mx-2 bg-white">
                                <div className="mt-2 py-3">
                                    <h4 className="px-5 text-black text-left ">ทรัพย์สินรวม (บาท)</h4>
                                    <div className="mt-2 ">
                                        <p className="px-5 text-left text-gray-500">ผู้ยื่น</p>
                                        <p className="px-5 text-left text-black">{person.assets}</p>
                                    </div>
                                    <div className="mt-2 ">
                                        <p className="px-5 text-left text-gray-500">คู่สมรส</p>
                                        <p className="px-5 text-left text-black">{person.spouseAssets}</p>
                                    </div>
                                    <div className="mt-2 ">
                                        <p className="px-5 text-left text-gray-500">บุตรที่ยังไม่บรรลุนิติภาวะ</p>
                                        <p className="px-5 text-left text-black">{person.childrenAssets}</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="mt-2 py-3">
                            <h4 className="px-5 text-black text-left ">หนี้สินรวม (บาท)</h4>
                            <div className="mt-2 ">
                                <p className="px-5 text-left text-gray-500">ผู้ยื่น</p>
                                <p className="px-5 text-left text-black">{person.debt}</p>
                            </div>
                            <div className="mt-2 ">
                                <p className="px-5 text-left text-gray-500">คู่สมรส</p>
                                <p className="px-5 text-left text-black">{person.spouseDebt}</p>
                            </div>
                            <div className="mt-2 ">
                                <p className="px-5 text-left text-gray-500">บุตรที่ยังไม่บรรลุนิติภาวะ</p>
                                <p className="px-5 text-left text-black">{person.childrenDebt}</p>
                            </div>
                        </div>

                        <hr className="mx-2 "/>

                        <div className="mt-2 py-3">
                            <h4 className="px-5 text-black text-left ">ผู้เกี่ยวข้อง</h4>
                            {relatives && relatives.length ? <div className="grid grid-cols-3">
                                {relatives.map(it =>
                                    <div className="mt-3 mb-1  p-3 text-center" key={it.id}>
                                        <img src={Avatar} alt="avatar image"
                                             className="mx-auto rounded-full shadow-xl w-10 md:w-10"/>
                                        <p className="text-sm mt-2"><a href={`/search-people?q=${it.name}`} target="_blank">{it.name}</a></p>
                                        <p className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-black bg-gray-400 rounded-full">{it.relativeType}</p>
                                    </div>)}
                            </div> : <div className="mt-3 bg-gray-100 p-3 text-center">ไม่มีข้อมูล</div>}
                        </div>
                    </div>
                </div>


                <div className="col-span-3 bg-main">
                    <div className="mx-5 my-5">

                        {person.partiesMemberids.length ?
                        <div className="relative bg-white  rounded-xl py-0 px-0 shadow-xl mt-3 overflow-hidden">
                            <div className="flex">
                                <div className="hidden md:flex text-center"
                                     style={{'background-color': '#FF0000', 'width': '120px'}}>
                                    <img src={ballot} alt="avatar image"
                                         className="mx-auto my-auto w-10"/>
                                </div>


                                <div className="py-5 w-full overflow-hidden">
                                    <h2 className="px-5 text-xl md:text-2xl lg:text-3xl font-bold">ความเกี่ยวข้องกับพรรคการเมือง</h2>

                                    {donateToParties.length ? <hr/> : <div></div>}
                                    <div className="px-5">
                                        <div className="grid grid-flow-row gap-4 py-3">
                                            {person.partiesMemberids.map(it => (
                                                <table className="table-auto grid-cols-12">
                                                    <thead>
                                                    <tr>
                                                        {/*<th className="text-center">พ.ศ.</th>*/}
                                                        <th className="text-left">สังกัดพรรค</th>
                                                        <th className="text-left">ตำแหน่ง</th>
                                                        {/*<th className="text-center">ผลการเลือกตั้ง</th>*/}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        {/*<td className="text-center"></td>*/}
                                                        <td className="text-left">
                                                            {it.role == 'senator' || it.role == 'legislator'? <p>ไม่เกี่ยวข้องกับพรรคการเมือง</p>: <p>{getPartyName(it.organizationid.name)}</p>}
                                                        </td>
                                                        <td className="text-left">
                                                            {it.role == 'senator' || it.role == 'legislator'? <p>-</p>: <p>{getRoleName(it)}</p>}

                                                        </td>
                                                        {/*<td className="text-center"></td>*/}
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>: <div/>}

                        <div className="relative bg-white  rounded-xl py-0 px-0 shadow-xl mt-3 overflow-hidden">
                            <div className="flex grid-flow-col gap-0 items-start">
                                <div className="hidden md:flex text-center" style={{'width': '120px', 'height': '160px'}}>
                                    <img src={Donation} alt="avatar image"
                                         className="mx-auto my-auto w-10"/>
                                </div>
                                <div className="px-5 py-5 w-full">
                                    <h2 className="text-xl md:text-2xl lg:text-3xl font-bold">ข้อมูลการบริจาค</h2>

                                    {donateToParties.length ?
                                        <ul className="list-none">
                                            {donateToParties.map(it =>
                                                <li className="mt-3 mb-1 pั-3" key={it.id}>
                                                    <div className="flex">
                                                        <div>
                                                            <div className="text-sm">บริจาคให้พรรค</div>
                                                            <div className="md:text-2xl text-xl font-bold">
                                                                {it.donatetoid.name}
                                                            </div>
                                                        </div>
                                                        <div className="ml-auto text-right">
                                                            <div className="text-sm">ยอดเงิน (บาท)</div>
                                                            <div
                                                                className="text-xl font-bold"
                                                                style={{'color': '#FF0000'}}>{it.totalReceiveAmount.toLocaleString('en-US', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}

                                                            </div>
                                                            <button
                                                                className="border border-gray-500 rounded px-2"
                                                                onClick={() => setExpandTransaction()}
                                                            >
                                                                รายละเอียด
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                        :
                                        <div className="">ไม่มีข้อมูล</div>
                                    }

                                    { isShowTransaction?
                                        <div>
                                            {donateTransactions && donateTransactions.length ?
                                                <table className="table-auto w-full mt-2">
                                                    <tbody>
                                                    {donateTransactions.map(it => <tr>
                                                            <td className="text-left px-1 py-1"><span
                                                                className="inline-block w-2 h-2 mr-2 bg-red-600 rounded-full"></span>
                                                            </td>
                                                            <td className="text-left px-1 py-1">{convertDateToThai(new Date(it.donatedat))}</td>
                                                            <td className="text-left px-1 py-1">{it.donatetoid.name}</td>
                                                            <td className="text-left px-1 py-1">{it.value.toLocaleString('en-US', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}</td>
                                                        </tr>
                                                    )}
                                                    </tbody>
                                                </table>
                                                : <div className="mt-3 bg-gray-100 p-3 text-center">ไม่มีข้อมูล</div>}

                                        </div>: <div></div>
                                    }


                                </div>
                            </div>
                        </div>

                        <div className="relative bg-white  rounded-xl py-0 px-0 shadow-xl mt-3 overflow-hidden">
                            <div className="flex grid-flow-col gap-0 ">
                                <div className="hidden md:flex text-center" style={{'width': '120px'}}>
                                    <img src={chairman} alt="avatar image"
                                         className="mx-auto my-auto w-10"/>
                                </div>
                                <div className="px-5 py-5 w-full">
                                    <h2 className="text-xl md:text-2xl lg:text-3xl font-bold">ข้อมูลการดำรงตำแหน่ง</h2>
                                    <p className="mt-3 text-l font-bold">ประวัติการดำรงตำแหน่งในอดีต</p>
                                    {previousJobDocuments && previousJobDocuments.length ?
                                        <table className="table-auto w-full mt-2  ">
                                            <tbody>
                                            {previousJobDocuments.map(it => <tr>
                                                    <td className="text-left px-1 py-1"><span
                                                        className="inline-block w-2 h-2 mr-2 bg-red-600 rounded-full"></span>
                                                    </td>
                                                    <td className="text-left px-1 py-1">{it.positionTitle} -
                                                        <Link
                                                            className="underline"
                                                            target="_blank"
                                                            to={"https://actai.co/Supplier?search=\"" + it.searchCompanyName + "\""}>{it.companyName}</Link></td>
                                                    <td className="text-left px-1 py-1">{it.endYear}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                        : <div className="mt-3 bg-gray-100 p-3 text-center">ไม่มีข้อมูล</div>}
                                </div>
                            </div>
                        </div>

                        <div className="relative bg-white  rounded-xl py-0 px-0 shadow-xl mt-3 overflow-hidden">
                            <div className="flex grid-flow-col gap-0 ">
                                <div className="hidden md:flex text-center" style={{'width': '120px'}}>
                                    <img src={hammer} alt="avatar image"
                                         className="mx-auto my-auto w-10"/>
                                </div>
                                <div className="px-5 py-5 w-full">
                                    <h2 className="text-xl md:text-2xl lg:text-3xl font-bold">ข้อมูลคดีความ</h2>
                                    {person.partiesDefendantsPersonids.length ? <ul className="list-none">
                                        {person.partiesDefendantsPersonids.map(it =>
                                            <li className="mt-3 mb-1  p-3" key={it.id}>
                                                {PersonalVerdictItem({item: it})}
                                            </li>)}
                                    </ul> : <div className="mt-3 bg-gray-100 p-3 text-center">ไม่มีข้อมูล</div>}
                                </div>
                            </div>
                        </div>

                        {relateOrgs.length ?
                        <div className=" mt-5">
                            <h3 className="text-xl md:text-2xl lg:text-3xl font-bold">องค์กรที่เกี่ยวข้อง</h3>
                            <div className="mt-2 grid md:grid-cols-2 lg:grid-cols-2 gap-4">
                                {relateOrgs.map(it => (
                                    <div className="">{ it.isParty ?
                                        <div><Link
                                            className="flex bg-white shadow py-5 px-5 rounded-l items-center translate duration-300 ease-in-out transform hover:-translate-y-1 hover:-translate-x-1"
                                            key={it.organizationid.id}
                                            to={`/political-party?id=${it.organizationid.id}`}>
                                            <img src={it.organizationid.image ? it.organizationid.image : Org}
                                                 alt="organization placeholder" className="w-12 rounded-full"/>
                                            <div className="font-sans ml-4 text-xl">
                                                <div>{getPartyName(it.organizationid.name)}</div>
                                                <div className="hidden text-sm">{getRoleName(it)}</div>
                                            </div>
                                            <ChevronRight className="ml-auto" width={32} color="grey"/>
                                        </Link></div>: <div>{
                                            it.companyName !== "" ?<Link
                                            className="flex bg-white shadow py-5 px-5 rounded-l items-center translate duration-300 ease-in-out transform hover:-translate-y-1 hover:-translate-x-1"
                                            target="_blank"
                                            to={"https://actai.co/Supplier?search=\"" + it.searchCompanyName + "\""}>
                                            <img src={Org}
                                                 alt="organization placeholder" className="w-12 rounded-full"/>
                                            <div className="font-sans ml-4 text-xl">
                                                <div>{it.companyName}</div>
                                            </div>
                                            <ChevronRight className="ml-auto" width={32} color="grey"/>
                                            </Link>:<Link />}</div>

                                    }</div>

                                ))}

                            </div>
                        </div>: <div/>}

                    </div>


                </div>
            </div>

        </Layout>
    )
}

Person.propTypes = {
    data: PropTypes.object
}

export default Person
