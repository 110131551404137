import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {format} from 'date-fns'
import {ChevronDown, ChevronUp} from '@styled-icons/octicons'

function PositionItem({item}) {

  // const [isShowDetails, setIsShowDetails] = useState(false)
  //
  // const toggle = () => {
  //   setIsShowDetails(!isShowDetails)
  // }

  let convertDateToThai = function (date) {
    var monthsTh =  [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม", ];

    return format(date, 'dd') + " " +
        monthsTh[parseInt(format(date, 'MM')) - 1] + " " +
        (parseInt(format(date, 'yyyy')) + 543)
  }

  return <div>
    <table className="table-auto w-full mt-2  ">
      <tbody>
      <tr>
        <td className="text-left px-1 py-1"><span className="inline-block w-2 h-2 mr-2 bg-red-600 rounded-full"></span></td>
        <td className="text-left px-1 py-1">{item.companyName}</td>
        <td className="text-left px-1 py-1">{item.endYear}</td>
      </tr>
      </tbody>
    </table>
  </div>
}

PositionItem.propTypes = {
  item: PropTypes.object,
}

export default PositionItem
